import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { Container } from '@mui/system'
import header from '../../../assets/images/opinions/header.png';
import result from '../../../assets/images/opinions/result.png';
import Question from '../../../components/Question';
import Intro from '../../../components/Intro';
import ProgressBar from '../../../components/ProgressBar';

import { useState } from 'react';
import AnimatedPage from '../../../components/Animations/AnimatedPages';

const Result = () => {

    const [showResult, setShowResult] = useState(false);

    const handleClickShowResult = () => setShowResult((show) => !show);
    return (
        <AnimatedPage>
            <section className='vote page'>
                <Intro image={header} />
                <div className='page__content mt-3'>
                    <Container>
                        <Grid container spacing={1}>
                            <Grid className="vote__right" item xs={12} sm={6}>
                                <Typography className='font-bolder text-primary mt-2' variant='h5' component='h2'>
                                    استطلاعات رأي
                                </Typography>

                                <div className='mt-1'>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">
                                            <Typography className='font-bolder' variant='h6' component='h3'>
                                                استطلاع عن مدى أهمية تغيير الانتخابات بشكل دوري
                                            </Typography>
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="female" control={<Radio />} label="ضرورية جدًا" />
                                            <FormControlLabel value="male" control={<Radio />} label="إلزامية" />
                                            <FormControlLabel value="other" control={<Radio />} label="ضرورية لكن غير إلزامية" />
                                            <FormControlLabel value="other2" control={<Radio />} label="غير ضرورية وغير إلزامية" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>


                            </Grid>
                            <Grid item xs={12} sm={6} container justifyContent='end' alignItems='center' className="vote__left" >
                                <Grid item md={6} xs={12}>

                                    {
                                        !showResult &&
                                        <Button onClick={handleClickShowResult}>
                                            <img src={result} alt="result" />
                                            <Typography variant='h6' component='h2' className='text-primary font-bolder'>
                                                نتيجة الاستطلاع
                                            </Typography>
                                        </Button>
                                    }

                                    {
                                        showResult &&
                                        <div>
                                            <ProgressBar text='ضرورية جدًا' percent='10' />
                                            <ProgressBar text='إلزامية' percent='40' />
                                            <ProgressBar text=' ضرورية لكن غير إلزامية' percent='20' />
                                            <ProgressBar text=' غير ضرورية وغير إلزامية' percent='30' />
                                        </div>

                                    }

                                </Grid>

                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </section>
        </AnimatedPage>
    )
}

export default Result