import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import dummyData from '../../../../plugins/data/data';
import UploadFile from '../../../Forms/UploadFile';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const AddEvent = () => {
    const [value, setValue] =useState<Dayjs | null>(null);
    return (

        <form>
            <div>
                <FormLabel>
                    صورة الفعالية
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <UploadFile title={'يرجى إرفاق صورة الفعالية التي تريدها'} />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    اسم  الفعالية
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        label={' اسم  الفعالية..'}
                    />
                </FormControl>
            </div>
            
            <div>
                <FormLabel>
                    نوع الفعالية
                </FormLabel>
                <FormControl fullWidth variant="outlined" >

                    <Autocomplete
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        options={dummyData}
                        renderInput={(params) => <TextField {...params} label="نوع الفعالية " />}
                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    مكان  الفعالية
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        label={' مكان  الفعالية..'}
                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    تاريخ الفعالية
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="تاريخ الفعالية"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                </FormControl>
            </div>
            <div>
                <FormLabel>
                    وصف الفعالية
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        id="outlined-multiline-static"
                        label={' وصف الفعالية..'}
                        multiline
                        rows={1}
                        defaultValue=""
                    />
                </FormControl>
            </div>
        </form>

    )
}

export default AddEvent