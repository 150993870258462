import { Box, Button, FormControl, FormLabel, Grid, TextField } from "@mui/material"
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import logo from '../../../assets/images/home/logo.svg';
import AnimatedPage from "../../../components/Animations/AnimatedPages";
import Preloader from "../../../components/Preloader";
import { useNavigate } from "react-router-dom";
const NewPassword = () => {
    const navigate = useNavigate();
    return (
        <AnimatedPage>
            <Preloader />
            <section className='auth'>
                <Grid container spacing={1}>

                    <Grid className="auth__right" item xs={12} sm={6}>
                        <div >
                            <Container>
                                <div className="text-center mt-2">
                                    <Link to="/" >
                                        <img className='logo' src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <h2>
                                    إنشاء كلمة مرور جديدة
                                </h2>
                                <div>
                                    <FormLabel>
                                        كلمة المرور الجديدة
                                    </FormLabel>
                                    <FormControl fullWidth variant="outlined" >
                                        <TextField
                                            fullWidth
                                            id="outlined-text-input"
                                            label={' قم بكتابة كلمة المرور هنا...'}
                                            type="password"
                                        />
                                    </FormControl>
                                </div>

                                <div>
                                    <FormLabel>
                                        تأكيد كلمة المرور الجديدة
                                    </FormLabel>
                                    <FormControl fullWidth variant="outlined" >
                                        <TextField
                                            fullWidth
                                            id="outlined-text-input"
                                            label={' قم بكتابة تأكيد كلمة المرور هنا...'}
                                            type="password"
                                        />
                                    </FormControl>
                                </div>

                                <Box p={1} mt={2}>
                                    <Grid container lg={12} justifyContent="center">
                                        <Grid item>
                                            <Button type="submit" variant="contained" onClick={() => navigate('/')} >
                                                إنشاء
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Container>
                        </div>
                    </Grid>
                    <Grid className="auth__left" item xs={12} sm={6}>

                    </Grid>
                </Grid>
            </section>
        </AnimatedPage>
    )
}

export default NewPassword