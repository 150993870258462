import { Button, Grid } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'

import Intro from '../../components/Intro'
import header from '../../assets/images/gallery/header.png';
import Item from '../../components/Home/Gallery/Item'
import gallery1 from '../../assets/images/home/gallery1.png';
import gallery2 from '../../assets/images/home/gallery2.png';
import gallery3 from '../../assets/images/home/gallery3.png';
import AnimatedPage from '../../components/Animations/AnimatedPages';
import AddAdGallery from '../../components/FormsWebsite/Gallery/AddGallery';
import DialogAdd from '../../components/Dialog/Add';
import DialogSuccess from '../../components/Dialog/Success';
import { useState } from 'react';

const Gallery = () => {

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: 'تمت إضافة الصور بنجاح',
            subTitle: 'سوف يتم مراجعة البيانات  مع الإدارة وإرسال الرد لك',
            onConfirm: () => { alert("hi") }
        })
    }
    return (
        <AnimatedPage>
            <section className='gallery page'>
                <Intro image={header} />
                <div className='page__content'>
                    <Container>
                        <Grid item xs container direction="row-reverse" spacing={2}>
                    
                            <DialogAdd icon={'+'} variant={'contained'} openDialog={openDialog} btnName="إضافة ألبوم" title="إضافة ألبوم">
                                <AddAdGallery />
                            </DialogAdd>
                        </Grid>

                        <div className='gallery__cards'>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={4}>
                                    <Item image={gallery1} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item image={gallery2} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item image={gallery3} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item image={gallery1} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item image={gallery2} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item image={gallery3} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item image={gallery1} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item image={gallery2} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item image={gallery3} title="مشاركة التعليم" id={1} />
                                </Grid>

                            </Grid>
                        </div>


                    </Container>
                </div>
                <DialogSuccess confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}></DialogSuccess>
            </section>
        </AnimatedPage>
    )
}

export default Gallery