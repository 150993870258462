import { Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../components/Intro'
import header from '../../assets/images/offers/header.png';
import offer1 from '../../assets/images/offers/offer1.png';
import offer2 from '../../assets/images/offers/offer2.png';
import DialogAdd from '../../components/Dialog/Add';
import AddOffer from '../../components/FormsWebsite/Offers/Add';
import AnimatedPage from '../../components/Animations/AnimatedPages';


const Offers = () => {
    return (
        <AnimatedPage>
            <section className='offers page'>
                <Intro image={header} />
                <div className='offers__content'>
                    <Container>
                        <Grid item xs container direction="row-reverse" spacing={2}>

                            <DialogAdd icon={'+'} variant={'contained'} openDialog={''} btnName="إضافة عرض" title="إضافة عرض">
                                <AddOffer></AddOffer>
                            </DialogAdd>
                        </Grid>

                        <div className='offers__content__card'>
                            <div className='offers__content__card__media'>
                                <img src={offer1} alt="offer" />
                            </div>
                            <Grid item xs container direction="row-reverse" spacing={2}>
                                <span className='hint'>
                                    العرض مقدم من أمجد الشامي
                                </span>
                            </Grid>
                            <Grid item>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item className='item-events'>
                                        <Typography className='font-bolder' variant="h5" component="h2">
                                            كود خصم 10% من نون
                                        </Typography>
                                    </Grid>

                                    <Grid item className='item-events'>
                                        <Typography color={'#9F7D4F'} className='font-bolder' variant="h6" component="h2">

                                            صالح حتى 5/1/2023
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <p>
                                    استخدم عرض AHO889 ,واستمتع بخصم خاص لأعضاء هذه العيلة على جميع منتجات نون السعودية
                                </p>


                            </Grid>
                        </div>

                        <div className='offers__content__card'>
                            <div className='offers__content__card__media'>
                                <img src={offer2} alt="offer" />
                            </div>
                            <Grid item xs container direction="row-reverse" spacing={2}>
                                <span className='hint'>
                                    العرض مقدم من أمجد الشامي
                                </span>
                            </Grid>
                            <Grid item>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item className='item-events'>
                                        <Typography className='font-bolder' variant="h5" component="h2">
                                            كود خصم 10% من نون
                                        </Typography>
                                    </Grid>

                                    <Grid item className='item-events'>
                                        <Typography color={'#9F7D4F'} className='font-bolder' variant="h6" component="h2">

                                            صالح حتى 5/1/2023
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <p>
                                    استخدم عرض AHO889 ,واستمتع بخصم خاص لأعضاء هذه العيلة على جميع منتجات نون السعودية
                                </p>


                            </Grid>
                        </div>

                    </Container>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default Offers