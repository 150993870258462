export const genders = [
    { label: 'ذكر', value: 'm' },
    { label: 'أنثى', value: 'f' },

]

export const maritalStatus  = [
    { label: 'أعزب', value: 'single' },
    { label: 'متزوج', value: 'married' },
    { label: 'مطلق', value: 'single' },
    { label: 'أرمل', value: 'married' },

]

