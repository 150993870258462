import { Button, Grid } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../components/Intro'
import Item from '../../components/Blog'
import header from '../../assets/images/gallery/header.png';
import gallery1 from '../../assets/images/home/gallery1.png';
import gallery2 from '../../assets/images/home/gallery2.png';
import gallery3 from '../../assets/images/home/gallery3.png';
import AnimatedPage from '../../components/Animations/AnimatedPages';
const Blogs = () => {
    return (

        <AnimatedPage>
            <section className='gallery page'>
                <Intro image={header} />
                <div className='page__content '>
                    <Container>
                        <div className='gallery__cards'>
                            <div className='pt-3'>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={4} sm={6}>
                                        <Item description='' image={gallery1} title="مشاركة التعليم" id={1} />
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={6}>
                                        <Item description='' image={gallery2} title="مشاركة التعليم" id={1} />
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={6}>
                                        <Item description='' image={gallery3} title="مشاركة التعليم" id={1} />
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={6}>
                                        <Item description='' image={gallery1} title="مشاركة التعليم" id={1} />
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={6}>
                                        <Item description='' image={gallery2} title="مشاركة التعليم" id={1} />
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={6}>
                                        <Item description='' image={gallery3} title="مشاركة التعليم" id={1} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Container>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default Blogs