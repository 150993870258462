
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "../components/Layouts/main";
import { AnimatePresence } from "framer-motion";
import Home from "../pages/Home";
import Family from "../pages/Family";
import Events from "../pages/Events";
import Offers from "../pages/Offers";
import Gallery from "../pages/Gallery";
import Blogs from "../pages/Blogs";
import Albums from "../pages/Gallery/Details";
import Requests from "../pages/Requests";
import Donations from "../pages/Donations";
import Auth from "../components/Layouts/Auth";
import Opinions from "../pages/Opinions";
import DonationPrevious from "../pages/Donations/Previous";
import RequestPrevious from "../pages/Requests/Previous";
import New from "../pages/Donations/New";
import Votes from "../pages/Votes";
import Result from "../pages/Opinions/Result";
import EventDetails from "../pages/Events/Details";
import BlogDetails from "../pages/Blogs/Details";
import Profile from "../pages/Profile";
import EditProfile from "../pages/Profile/Edit";
import Login from "../pages/Auth/Login";
import ResetPasswordPhone from "../pages/Auth/ResetPassword/Phone";
import ResetPasswordEmail from "../pages/Auth/ResetPassword/Email";
import Register from "../pages/Auth/Register";
import VerifyCodeEmail from "../pages/Auth/VerifyCode/Email";
import VerifyCodePhone from "../pages/Auth/VerifyCode/Phone";
import NewPassword from "../pages/Auth/NewPassword";
import GalleryDetails from "../pages/Gallery/Details";
import RequireAuth from "../plugins/features/auth/RequireAuth";
import Welcome from "../plugins/features/auth/Welcome";
import UsersList from "../plugins/features/users/UsersList";
import RequireUnAuth from "../plugins/features/auth/RequireUnAuth";
import Soon from "../pages/soon";


function Routing() {
    return (
        <Router>
            <AnimatePresence mode="wait">
                <Routes>
                    {/* Main layout */}
                    <Route path="/" element={<Main />} >
                        <Route index element={<Soon/>} />
                        <Route path="family" element={<Family />} />
                        <Route path="events" element={<Events />} />
                        <Route path="events/details" element={<EventDetails />} />
                        <Route path="offers" element={<Offers />} />
                        <Route path="gallery" element={<Gallery />} />
                        <Route path="gallery/details" element={<GalleryDetails />} />
                        <Route path="blogs" element={<Blogs />} />
                        <Route path="blogs/details" element={<BlogDetails />} />
                        <Route path="albums" element={<Albums />} />
                        <Route path="requests" element={<Requests />} />
                        <Route path="requests/previous" element={<RequestPrevious />} />
                        <Route path="donations" element={<Donations />} />
                        <Route path="donations/previous" element={<DonationPrevious />} />
                        <Route path="donations/new" element={<New />} />
                        <Route path="opinions" element={<Opinions />} />
                        <Route path="opinions/result" element={<Result />} />
                        <Route path="votes" element={<Votes />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="profile/edit" element={<EditProfile />} />
                    </Route>
                    {/* Auth layout */}
                    <Route element={<RequireUnAuth />} >
                        <Route path="/auth/" element={<Auth />} >
                            <Route path="login" element={<Login />} />
                            <Route path="register" element={<Register />} />
                            <Route path="reset-password/phone" element={<ResetPasswordPhone />} />
                            <Route path="reset-password/email" element={<ResetPasswordEmail />} />
                            <Route path="verify-code/email" element={<VerifyCodeEmail />} />
                            <Route path="verify-code/phone" element={<VerifyCodePhone />} />
                            <Route path="new-password" element={<NewPassword />} />
                        </Route>
                    </Route>
                    {/* protected routes */}
                    <Route element={<RequireAuth />}>
                        <Route path="welcome" element={<Welcome />} />
                        <Route path="users" element={<UsersList />} />
                    </Route>
                </Routes>
            </AnimatePresence>
        </Router>
    );
}

export default Routing;
