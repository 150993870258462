import { Autocomplete, Button, Card, CardActionArea, CardContent, FormControl, FormLabel, Grid, InputLabel, OutlinedInput, Paper, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Public from '../../../assets/images/icons/public.svg';
import header from '../../../assets/images/opinions/header.png';
import Intro from '../../../components/Intro';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import DialogFire from '../../../components/Dialog/Fire';
import { useState } from "react"
import ProfilePrivacy from '../../../components/FormsWebsite/Profile/Privacy';
import dummyData from '../../../plugins/data/data';
import { genders, maritalStatus } from '../../../plugins/data/selectData';
const EditProfile = () => {
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: 'الوظيفة',
            subTitle: '',
            onConfirm: () => { alert("hi") }
        })
    }
    return (
        <AnimatedPage>
            <DialogFire btnName="حفظ التعديلات" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                <ProfilePrivacy></ProfilePrivacy>
            </DialogFire>
            <section className='profile'>
                <Intro image={header} />
                <div className='mt-2'>
                    <Container >
                        <Grid container xs spacing={3} justifyContent={'center'}>
                            <Grid item xs={12} className='px-0' >
                                <Paper className='profile__left'>

                                    <div className='text-center pt-1'>
                                        <Typography fontWeight={'bolder'} gutterBottom variant="h5" component="div">
                                            تعديل البيانات الشخصية
                                        </Typography>

                                    </div>

                                    <div className='mt-2'>
                                        <Grid container alignItems={'center'} >
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            الاسم الأول
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>

                                                            <FormControl fullWidth variant="outlined" >
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={' الاسم الأول'}
                                                                    type="text"

                                                                />
                                                            </FormControl>


                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            اسم الأب
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={'اسم الأب'}
                                                                    type="text"

                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div >
                                        <Grid container alignItems={'center'} >
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            اسم العائلة
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>

                                                            <FormControl fullWidth variant="outlined" >
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={' اسم العائلة'}
                                                                    type="text"

                                                                />
                                                            </FormControl>


                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            اسم العائلة
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={'اسم العائلة '}
                                                                    type="text"

                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div >
                                        <Grid container alignItems={'center'} >
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            الجنس
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>

                                                            <FormControl fullWidth variant="outlined">

                                                                <Autocomplete
                                                                    fullWidth
                                                                    disablePortal
                                                                    id="combo-box-demo"
                                                                    options={genders}


                                                                    renderInput={(params) => <TextField {...params} label="الجنس  " />}
                                                                />
                                                            </FormControl>


                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            رقم الهوية
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={'رقم الهوية '}
                                                                    type="text"

                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div >
                                        <Grid container alignItems={'center'} >
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            البريد الالكتروني
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>

                                                            <FormControl fullWidth variant="outlined" >
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={' البريد الالكتروني'}
                                                                    type="text"

                                                                />
                                                            </FormControl>


                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            رقم الهاتف
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={'رقم الهاتف'}
                                                                    type="text"

                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div >
                                        <Grid container alignItems={'center'} >
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            تاريخ الميلاد
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>

                                                            <FormControl fullWidth variant="outlined" >
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={' تاريخ الميلاد '}
                                                                    type="text"

                                                                />
                                                            </FormControl>


                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            الحالة الاجتماعية
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>

                                                            <FormControl fullWidth variant="outlined">

                                                                <Autocomplete
                                                                    fullWidth
                                                                    disablePortal
                                                                    id="combo-box-demo"
                                                                    options={maritalStatus}


                                                                    renderInput={(params) => <TextField {...params} label=" الحالة الاجتماعية" />}
                                                                />
                                                            </FormControl>

                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div >
                                        <Grid container alignItems={'center'} >
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            الوظيفة
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>

                                                            <FormControl fullWidth variant="outlined" >
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={' الوظيفة'}
                                                                    type="text"

                                                                />
                                                            </FormControl>


                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container md={6} >
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            عنوان العمل
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={'عنوان العمل'}
                                                                    type="text"

                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div >
                                        <Grid container alignItems={'center'} >

                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            عنوان الإقامة
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={'عنوان الإقامة '}
                                                                    type="text"

                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div >
                                        <Grid container alignItems={'center'} >
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            رابط الفيس بوك
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>

                                                            <FormControl fullWidth variant="outlined" >
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={' رابط الفيس بوك '}
                                                                    type="text"

                                                                />
                                                            </FormControl>


                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container md={6}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            رابط الانستاغرام
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <TextField
                                                                    dir="rtl"
                                                                    fullWidth

                                                                    id="outlined-text-input"
                                                                    label={'رابط الانستاغرام'}
                                                                    type="text"

                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item>
                                                            <Button
                                                                onClick={() => openDialog()}
                                                                className='profile__add'>

                                                                <img
                                                                    src={Public}
                                                                    alt="public"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div >
                                        <Grid container alignItems={'center'} >
                                            <Grid container xs={12}>
                                                <Grid container flexDirection={'column'}>
                                                    <Grid item>
                                                        <FormLabel>
                                                            نبذة مختصرة عنك
                                                            <span className='text-danger'>*</span>
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item flexGrow={1}>
                                                            <FormControl fullWidth variant="outlined" >
                                                                <TextField
                                                                    fullWidth
                                                                    label={'  نبذة مختصرة عنك...'}
                                                                    multiline
                                                                    rows={1}

                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className='mt-2 text-center'>
                                        <Button className='profile__save' variant='contained'>
                                            حفظ التعديلات
                                        </Button>
                                    </div>
                                </Paper>
                            </Grid>

                        </Grid>
                    </Container>
                </div>
            </section >
        </AnimatedPage>
    )
}



export default EditProfile