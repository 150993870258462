import { baseApi } from "../../api/base"


export const login = baseApi.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/login',
                method: 'POST'
            })
        }),
    })
})

export const {
    useLoginMutation
} = login