import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import * as React from 'react';
import { Dayjs } from 'dayjs';
import PublicIcon from '../../../../assets/images/icons/public.svg';
import PrivateIcon from '../../../../assets/images/icons/private.svg';
import GenderIcon from '../../../../assets/images/icons/gender.svg';


const ProfilePrivacy = () => {
    const [value, setValue] = React.useState<Dayjs | null>(null);
    return (
        <form>
            <div>
                <FormControl fullWidth>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <Grid container>
                            <Grid marginBottom={3} container xs={11}>
                                <Grid md={2} xs={12}>
                                    <div className='icon-privacy'>
                                        <img src={PublicIcon} alt="pubic" />
                                    </div>
                                </Grid>
                                <Grid md={10} xs={12}>
                                    <Typography marginX={1} variant='h6' component={'h2'} fontWeight={'bolder'}>
                                        العامة
                                    </Typography>
                                    <Typography marginX={1} variant='subtitle1' component={'div'} >
                                        متاح لكل أعضاء الموقع
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel value="public" control={<Radio className='privacy-checked' />} label="" />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid marginBottom={3} container xs={11}>
                                <Grid md={2} xs={12}>
                                    <div className='icon-privacy'>
                                        <img src={GenderIcon} alt="pubic" />
                                    </div>
                                </Grid>
                                <Grid md={10} xs={12}>
                                    <Typography marginX={1} variant='h6' component={'h2'} fontWeight={'bolder'}>
                                        نفس الجنس
                                    </Typography>
                                    <Typography marginX={1} variant='subtitle1' component={'div'} >
                                        متاح لنفس الأعضاء من الجنس فقط
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel value="gender" control={<Radio className='privacy-checked' />} label="" />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid marginBottom={3} container xs={11}>
                                <Grid md={2} xs={12}>
                                    <div className='icon-privacy'>
                                        <img src={PrivateIcon} alt="pubic" />
                                    </div>
                                </Grid>
                                <Grid md={10} xs={12}>
                                    <Typography marginX={1} variant='h6' component={'h2'} fontWeight={'bolder'}>
                                        أنا فقط
                                    </Typography>
                                    <Typography marginX={1} variant='subtitle1' component={'div'} >
                                        لن تظهر لأي شخص
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel value="private" control={<Radio className='privacy-checked' />} label="" />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </div>


        </form>
    )
}

export default ProfilePrivacy