import { Autocomplete,  FormControl, FormLabel, TextField } from '@mui/material'

import dummyData from '../../../../plugins/data/data';
import UploadFile from '../../../Forms/UploadFile';

const AddEvent = () => {

    return (

        <form>
            <div>
                <FormLabel>
                    يرجى إرفاق صورة المناسبة التي تريدها
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <UploadFile title={'يرجى إرفاق صورة المناسبة التي تريدها'}/>
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    نوع المناسبة
                </FormLabel>
                <FormControl fullWidth variant="outlined" >

                    <Autocomplete
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        options={dummyData}


                        renderInput={(params) => <TextField {...params} label="نوع المناسبة " />}
                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    سبب المناسبة
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        id="outlined-multiline-static"
                        label={' سبب المناسبة..'}
                        multiline
                        rows={1}
                        defaultValue=""
                    />
                </FormControl>
            </div>


        </form>



    )
}

export default AddEvent