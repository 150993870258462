import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import '../../plugins/i18n/index'
import Preloader from '../Preloader';
import AppFooter from './Footer';
import AppHeader from './Header';


export default function Layout() {

    return (
        <Fragment>
            {/* <Preloader/>
            <AppHeader /> */}
            <Outlet />
            {/* <AppFooter /> */}
        </Fragment>
    );
}
