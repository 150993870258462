import { baseApi } from "../../api/base"


export const getUsers = baseApi.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: () => '/users',
            keepUnusedDataFor: 5,
        })
    })
})

export const { useGetUsersQuery } = getUsers 