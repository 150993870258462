import { Checkbox, FormControl, FormControlLabel, FormLabel, TextField } from '@mui/material'
import * as React from 'react';
import { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import { FormProvider } from 'react-hook-form'

const AddFather = () => {
    const [value, setValue] = React.useState<Dayjs | null>(null);
    return (

        <form>
            <div>
                <FormLabel>
                اسم الجد الأكبر
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        id="outlined-text-input"
                        label={' قم بكتابة اسم الجد الأكبر هنا...'}
                        type="text"
                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                   اسم الجد
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        id="outlined-text-input"
                        label={' قم بكتابة اسم الجد هنا...'}
                        type="text"
                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                   اسم الأب
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        id="outlined-text-input"
                        label={' قم بكتابة اسم الأب هنا...'}
                        type="text"
                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    رقم الهوية
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        id="outlined-text-input"
                        label={' قم بكتابة رقم الهوية هنا...'}
                        type="text"
                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    تاريخ الميلاد
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="تاريخ الميلاد"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                </FormControl>
            </div>
            <div>
                <FormLabel>
                    رقم الهاتف
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        id="outlined-text-input"
                        label={' قم بكتابة    رقم الهاتف هنا...'}
                        type="text"
                    />
                </FormControl>

                <FormControlLabel className='checked-box' control={<Checkbox defaultChecked />} label="إرسال اللينك عبر الهاتف" />


            </div>
            <div>
                <FormLabel>
                    البريد الألكترونى
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        id="outlined-text-input"
                        label={' قم بكتابة البريد الألكترونى هنا...'}
                        type="text"
                    />
                </FormControl>

                <FormControlLabel className='checked-box' control={<Checkbox defaultChecked />} label="إرسال اللينك عبر البريد" />
            </div>
        </form>



    )
}

export default AddFather