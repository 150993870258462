import { Button, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import gallery1 from '../../assets/images/home/gallery1.png';
import { useNavigate } from "react-router-dom";
import DialogAdd from '../Dialog/Add';
import AddAdvance from '../FormsWebsite/Requests/AddAdvance';
const Action = ({ image, title, description, btns, page,children }) => {

  const navigate = useNavigate();
  return (
    <div>
      <Card className='action'>
        <CardActionArea>
          <img
            className='action__media'
            src={image}
            alt="icon"
          />
          <CardContent>
            <Typography className='action__title font-bolder' gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography className='action__description' variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
          {
            btns &&
            <div className='action__btns'>

             {children}

              <Button variant='outlined' fullWidth onClick={() => navigate(page)}>
                الطلبات السابقة
              </Button>
            </div>
          }
        </CardActionArea>
      </Card>
    </div>
  )
}

export default Action