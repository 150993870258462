import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { Container } from '@mui/system'
import person1 from '../../assets/images/opinions/person1.png';
import person2 from '../../assets/images/opinions/person2.png';
import person3 from '../../assets/images/opinions/person3.png';
import person4 from '../../assets/images/opinions/person4.png';
import header from '../../assets/images/opinions/header.png';
import Question from '../../components/Question';
import Intro from '../../components/Intro';
import AnimatedPage from '../../components/Animations/AnimatedPages';

const Votes = () => {
    return (
        <AnimatedPage>
            <section className='vote page'>
                <Intro image={header} />
                <div className='page__content mt-3'>
                    <Container>
                        <Grid container spacing={1} className={'page__content__container'}>
                            <Grid className="vote__right" item xs={12} sm={6}>
                                <Typography className='font-bolder text-primary mt-2' variant='h5' component='h2'>
                                    انتخابات
                                </Typography>

                                <div className='mt-1'>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">
                                            <Typography className='font-bolder' variant='h6' component='h3'>
                                                انتخابات على منصب رئيس مجلس الأمناء
                                            </Typography>
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="female" control={<Radio />} label="د/ فؤاد عمر إبراهيم أزهر" />
                                            <FormControlLabel value="male" control={<Radio />} label="د/ كمال إبراهيم أحمد زاهر" />
                                            <FormControlLabel value="other" control={<Radio />} label="د/ علي عبد العزيز علي أزهر" />
                                            <FormControlLabel value="other2" control={<Radio />} label="د/ هاني جميل عبد القادر أزهر" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>

                                <Button variant='contained'>
                                    تصويت
                                </Button>
                            </Grid>
                            <Grid container spacing={5} className="vote__left" item xs={12} sm={6}>
                                <Grid item xs={6} >
                                    <Question image={person1} title='د/ كمال إبراهيم أحمد أزهر'></Question>
                                </Grid>
                                <Grid item xs={6} >
                                    <Question image={person2} title='د/ كمال إبراهيم أحمد أزهر'></Question>
                                </Grid>
                                <Grid item xs={6} >
                                    <Question image={person3} title='د/ كمال إبراهيم أحمد أزهر'></Question>
                                </Grid>

                                <Grid item xs={6} >
                                    <Question image={person4} title='د/ كمال إبراهيم أحمد أزهر'></Question>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </section>
        </AnimatedPage>
    )
}

export default Votes