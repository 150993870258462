import { Autocomplete, FormControl, FormLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'

import { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { TimePicker } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import AddressImage from "../../../../assets/images/icons/address.svg"



const EditRequest = () => {
    const [value, setValue] = useState<Dayjs | null>(null);
    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue);
    };

    return (

        <form>
            <div>
                <FormLabel>
                    نوع الطلب
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        label={'مناسبة...'}

                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    تاريخ المناسبة
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label=" تاريخ المناسبة"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                </FormControl>
            </div>

            <div>
                <FormLabel>
                    توقيت المناسبة
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                        <TimePicker
                            label=" توقيت المناسبة"
                            value={value}
                            onChange={handleChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Stack>
                </LocalizationProvider>
            </div>
            <div>
                <FormLabel>
                    عنوان المناسبة
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <InputLabel htmlFor="outlined-adornment-password"> عنوان المناسبة</InputLabel>
                    <OutlinedInput
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                >
                                    <img src={AddressImage} alt="address" />
                                </IconButton>
                            </InputAdornment>
                        }

                    />
                </FormControl>
            </div>

            <div>
                <FormLabel>
                    ملاحظات إضافية
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        label={'ملاحظات إضافية..'}
                        multiline
                        rows={1}
                        defaultValue=""
                    />
                </FormControl>
                <div className='hint-desc'>
                    أرسل لنا ملاحظاتك عن الطلب الخاص بك
                </div>
            </div>


        </form>



    )
}

export default EditRequest