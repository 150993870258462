import React from 'react'

const Intro = ({image}) => {
  return (
    // <div className='intro' style={{'background':'linear-gradient(to left,rgba(242, 184, 109, 0.4), rgba(242, 184, 109, 0.4)), url('+image+') '}}>
    // </div>
     <div className='intro' style={{'background':'linear-gradient(to left,rgba(255, 255, 255, 0.7),rgba(255, 255, 255, 0.5) ), url('+image+') '}}>
     </div>
  )
}

export default Intro

