import { Button, ButtonBase, Grid, Paper, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../../components/Intro'
import Item from '../../../components/Blog'
import header from '../../../assets/images/donations/header.png';
import AnimatedPage from '../../../components/Animations/AnimatedPages';



const DonationPrevious = () => {
    return (
        <AnimatedPage>
            <section className='donations page bg-white'>
                <Intro image={header} />
                <div className='page__content mt-3'>
                    <div className='donations__prev'>
                        <Container>
                            <Typography className='font-bolder' gutterBottom variant="h5" component="h2">
                                تبرعات سابقة
                            </Typography>
                            <Paper className='donations__prev__card paper'
                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs={10}>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom >
                                                    <span >
                                                        التاريخ:
                                                    </span>
                                                    <span className='text-primary'>
                                                        20/2/2022
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        الوقت:
                                                    </span>
                                                    <span className='text-primary'>
                                                        10:20 pm
                                                    </span>
                                                </Typography>

                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        قيمة التبرع:
                                                    </span>

                                                    <span className='text-primary'>
                                                        200
                                                        ريال
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        ملاحظات:                                                 </span>
                                                    <span className='text-primary'>
                                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث هذا النص هو مثال لنص يمكن أن يستبدل هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث هذا النص هو مثال لنص يمكن أن يستبدل
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item className='donations__prev__card__notify' xs={2}>
                                                <Typography variant="subtitle1" className='text-center font-bolder' component="div">
                                                    <span className='status'>
                                                        حالة التحويل
                                                    </span>
                                                    <br />
                                                    <span className='text-success'>
                                                        مقبول
                                                    </span>
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper className='donations__prev__card paper'
                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs={10}>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom >
                                                    <span >
                                                        التاريخ:
                                                    </span>
                                                    <span className='text-primary'>
                                                        20/2/2022
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        الوقت:
                                                    </span>
                                                    <span className='text-primary'>
                                                        10:20 pm
                                                    </span>
                                                </Typography>

                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        قيمة التبرع:
                                                    </span>

                                                    <span className='text-primary'>
                                                        200
                                                        ريال
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        ملاحظات:                                                 </span>
                                                    <span className='text-primary'>
                                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث هذا النص هو مثال لنص يمكن أن يستبدل هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث هذا النص هو مثال لنص يمكن أن يستبدل
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item className='donations__prev__card__notify' xs={2}>
                                                <Typography variant="subtitle1" className='text-center font-bolder' component="div">
                                                    <span className='status'>
                                                        حالة التحويل
                                                    </span>
                                                    <br />
                                                    <span className='text-danger'>
                                                        مرفوض
                                                    </span>
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>


                            <Paper className='donations__prev__card paper'
                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs={10}>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom >
                                                    <span >
                                                        التاريخ:
                                                    </span>
                                                    <span className='text-primary'>
                                                        20/2/2022
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        الوقت:
                                                    </span>
                                                    <span className='text-primary'>
                                                        10:20 pm
                                                    </span>
                                                </Typography>

                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        قيمة التبرع:
                                                    </span>

                                                    <span className='text-primary'>
                                                        200
                                                        ريال
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        ملاحظات:                                                 </span>
                                                    <span className='text-primary'>
                                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث هذا النص هو مثال لنص يمكن أن يستبدل هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث هذا النص هو مثال لنص يمكن أن يستبدل
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item className='donations__prev__card__notify' xs={2}>
                                                <Typography variant="subtitle1" className='text-center font-bolder' component="div">
                                                    <span className='status'>
                                                        حالة التحويل
                                                    </span>
                                                    <br />
                                                    <span className='text-pending'>
                                                        قيد الانتظار
                                                    </span>
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>




                        </Container>
                    </div>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default DonationPrevious