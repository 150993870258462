import { FormControl, FormLabel, TextField } from '@mui/material'
import UploadFile from '../../../Forms/UploadFile';

const AddDonation = () => {
    return (
        <form>
            <div>
                <FormLabel>
                    إرفاق صورة من التحويل
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <UploadFile title={' يرجى إرفاق صورة التحويل للتبرع الذي تريده '} />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    قيمة التبرع
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        label={' قيمة التبرع..'}
                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    ملاحظات
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        label={' ملاحظات...'}
                        multiline
                        rows={1}
                        defaultValue=""
                    />
                </FormControl>
            </div>
        </form>
    )
}

export default AddDonation