
import Intro from '../../../components/Intro'
import header from '../../../assets/images/requests/header.png';
import requestImage from '../../../assets/images/icons/request.png';
import { Container } from '@mui/system';
import { ButtonBase, Grid, Paper, Typography } from '@mui/material';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import DialogDelete from '../../../components/Dialog/Delete';

import { useState } from 'react';
import DialogFire from '../../../components/Dialog/Fire';
import EditRequest from '../../../components/FormsWebsite/Requests/Edit';

const RequestPrevious = () => {
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: '',
            subTitle: '',
            onConfirm: () => { alert("hi") }
        })
    }
    return (
        <AnimatedPage>
            <DialogFire btnName="إرسال" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                <EditRequest></EditRequest>
            </DialogFire>

            <section className='requests events page'>
                <Intro image={header} />
                <div className='events__content page__content  mt-3'>
                    <Container>
                        <Grid item xs container justifyContent='center' spacing={2}>
                            <Typography className='font-bolder' gutterBottom variant="h5" component="h2">
                                الطلبات السابقة
                            </Typography>
                        </Grid>
                        <div className='events__content__card'>

                            <Paper className='paper'

                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase onClick={() => openDialog()} sx={{ width: 128, height: 128 }}>
                                            <img src={requestImage} alt="قثضعثسف" className='events__content__card__media' />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs={10} container direction="column" spacing={2}>
                                            <Grid className='pointer' item xs onClick={() => openDialog()}>
                                                <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                    طلب رقم 1
                                                </Typography>
                                                <Typography className='text-name' variant="body2" gutterBottom>
                                                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                </Typography>

                                            </Grid>

                                        </Grid>
                                        <Grid item className='notify' md={2}>
                                            <Typography variant="subtitle1" className=' font-bolder' component="div">
                                                <div className='action-btn text-danger remove-btn'>
                                                    <DialogDelete title={'هل أنت متأكد من حذف الطلب رقم 2'}></DialogDelete>
                                                </div>
                                                <br />

                                                <span className='status text-primary'>
                                                    جاري مراجعة الطلب
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className='events__content__card'>

                            <Paper className='paper'

                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase onClick={() => openDialog()} sx={{ width: 128, height: 128 }}>
                                            <img src={requestImage} alt="قثضعثسف" className='events__content__card__media' />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs={10} container direction="column" spacing={2}>
                                            <Grid className='pointer' item xs onClick={() => openDialog()}>
                                                <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                    طلب رقم 1
                                                </Typography>
                                                <Typography className='text-name' variant="body2" gutterBottom>
                                                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                </Typography>

                                            </Grid>

                                        </Grid>
                                        <Grid item className='notify' md={2}>
                                            <Typography variant="subtitle1" className=' font-bolder' component="div">
                                                <div className='action-btn text-danger remove-btn'>
                                                    <DialogDelete title={'هل أنت متأكد من حذف الطلب رقم 2'}></DialogDelete>
                                                </div>
                                                <br />

                                                <span className='status text-primary'>
                                                    جاري مراجعة الطلب
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>

                        <div className='events__content__card'>

                            <Paper className='paper'

                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase onClick={() => openDialog()} sx={{ width: 128, height: 128 }}>
                                            <img src={requestImage} alt="قثضعثسف" className='events__content__card__media' />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs={10} container direction="column" spacing={2}>
                                            <Grid className='pointer' item xs onClick={() => openDialog()}>
                                                <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                    طلب رقم 1
                                                </Typography>
                                                <Typography className='text-name' variant="body2" gutterBottom>
                                                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                </Typography>

                                            </Grid>

                                        </Grid>
                                        <Grid item className='notify' md={2}>
                                            <Typography variant="subtitle1" className=' font-bolder' component="div">
                                                <div className='action-btn text-danger remove-btn'>
                                                    <DialogDelete title={'هل أنت متأكد من حذف الطلب رقم 2'}></DialogDelete>
                                                </div>
                                                <br />

                                                <span className='status text-primary'>
                                                    جاري مراجعة الطلب
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>

                        <div className='events__content__card'>

                            <Paper className='paper'

                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase onClick={() => openDialog()} sx={{ width: 128, height: 128 }}>
                                            <img src={requestImage} alt="قثضعثسف" className='events__content__card__media' />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs={10} container direction="column" spacing={2}>
                                            <Grid className='pointer' item xs onClick={() => openDialog()}>
                                                <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                    طلب رقم 1
                                                </Typography>
                                                <Typography className='text-name' variant="body2" gutterBottom>
                                                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                </Typography>

                                            </Grid>

                                        </Grid>
                                        <Grid item className='notify' md={2}>
                                            <Typography variant="subtitle1" className=' font-bolder' component="div">
                                                <div className='action-btn text-danger remove-btn'>
                                                    <DialogDelete title={'هل أنت متأكد من حذف الطلب رقم 2'}></DialogDelete>
                                                </div>
                                                <br />

                                                <span className='status text-primary'>
                                                    جاري مراجعة الطلب
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>

                        <div className='events__content__card'>

                            <Paper className='paper'

                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase onClick={() => openDialog()} sx={{ width: 128, height: 128 }}>
                                            <img src={requestImage} alt="قثضعثسف" className='events__content__card__media' />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs={10} container direction="column" spacing={2}>
                                            <Grid className='pointer' item xs onClick={() => openDialog()}>
                                                <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                    طلب رقم 1
                                                </Typography>
                                                <Typography className='text-name' variant="body2" gutterBottom>
                                                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                </Typography>

                                            </Grid>

                                        </Grid>
                                        <Grid item className='notify' md={2}>
                                            <Typography variant="subtitle1" className=' font-bolder' component="div">
                                                <div className='action-btn text-danger remove-btn'>
                                                    <DialogDelete title={'هل أنت متأكد من حذف الطلب رقم 2'}></DialogDelete>
                                                </div>
                                                <br />

                                                <span className='status text-primary'>
                                                    جاري مراجعة الطلب
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>

                        <div className='events__content__card'>

                            <Paper className='paper'

                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase onClick={() => openDialog()} sx={{ width: 128, height: 128 }}>
                                            <img src={requestImage} alt="قثضعثسف" className='events__content__card__media' />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs={10} container direction="column" spacing={2}>
                                            <Grid className='pointer' item xs onClick={() => openDialog()}>
                                                <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                    طلب رقم 1
                                                </Typography>
                                                <Typography className='text-name' variant="body2" gutterBottom>
                                                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                </Typography>

                                            </Grid>

                                        </Grid>
                                        <Grid item className='notify' md={2}>
                                            <Typography variant="subtitle1" className=' font-bolder' component="div">
                                                <div className='action-btn text-danger remove-btn'>
                                                    <DialogDelete title={'هل أنت متأكد من حذف الطلب رقم 2'}></DialogDelete>
                                                </div>
                                                <br />

                                                <span className='status text-primary'>
                                                    جاري مراجعة الطلب
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>


                    </Container>
                </div>
            </section>
        </AnimatedPage>
    )
}

export default RequestPrevious