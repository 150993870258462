import { motion } from "framer-motion";
import Preloader from "../Preloader";
import React from "react";

const animations = {
  initial: { opacity: 0, y: 0, x: 0, },
  animate: { opacity: 1, y: 0, x: 0, },
  exit: { opacity: 0, y: 0, x: 0, },
};



const AnimatedPage = ({ children }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: .7 }}
    >

      {children}

    </motion.div>
  );
};

export default AnimatedPage;