import { Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../components/Intro'
import Item from '../../components/Blog'
import header from '../../assets/images/donations/header.png';
import newDonation from '../../assets/images/donations/new.svg';
import oldDonation from '../../assets/images/donations/old.svg';

import Action from '../../components/DonationAction';
import AnimatedPage from '../../components/Animations/AnimatedPages';
const Donations = () => {
    return (
        <AnimatedPage>
            <section className='donations page bg-white'>
                <Intro image={header} />
                <div className='page__content t mt-3'>
                    <Container>

                        <div className='donations_content pb-2'>
                            <Typography className='font-bolder' variant='h5' component='h3'>
                                بنك عائلة آل أزهر
                            </Typography>
                            <p>
                                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث هذا النص هو مثال لنص يمكن أن يستبدل هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث هذا النص هو مثال لنص يمكن أن يستبدل
                            </p>
                            <Typography className='font-bolder' variant='h5' component='h3'>
                                أسس ومنهج الصندوق
                            </Typography>
                            <p>
                                1- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة
                            </p>
                            <p>
                                2- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة
                            </p>
                            <p>
                                3- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة
                            </p>
                            <p>
                                4- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة
                            </p>

                        </div>
                        <Grid container spacing={8} justifyContent='center' className='text-center'>
                            <Grid item xs={12} sm={6}>
                                <Action
                                    page={'/donations/new'}
                                    image={newDonation}
                                    title='تبرع جديد'
                                    description='هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، '
                                />

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Action
                                    page={'/donations/previous'}
                                    image={oldDonation}
                                    title='تبرعات سابقة'
                                    description='هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، '
                                />

                            </Grid>


                        </Grid>


                    </Container>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default Donations