import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormLabel, TextField } from '@mui/material'
import * as React from 'react';
import { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import { FormProvider } from 'react-hook-form'
import dummyData from '../../../../plugins/data/data';
import UploadFile from '../../../Forms/UploadFile';

const AddAdGallery = () => {
    const [value, setValue] = React.useState<Dayjs | null>(null);
    return (

        <form>
            <div>
                <FormLabel>
                    صور الألبوم
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <UploadFile title={' يرجى إرفاق صورة الالبوم التي تريدها'} />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    اختيار الألبوم
                </FormLabel>
                <FormControl fullWidth variant="outlined" >

                    <Autocomplete
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        options={dummyData}
                        renderInput={(params) => <TextField {...params} label="اختيار الألبوم  " />}
                    />
                </FormControl>
            </div>

            <div>
                <FormLabel>
                    عنوان الصورة / الفيديو
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        label={'عنوان الصورة / الفيديو..'}

                    />
                </FormControl>
            </div>

            <div>
                <FormLabel>
                    تفاصيل الصورة / الفيديو
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        label={'عنوان الصورة / الفيديو..'}
                        multiline
                        rows={1}
                        defaultValue=""
                    />
                </FormControl>
            </div>


        </form>



    )
}

export default AddAdGallery