import * as React from 'react';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import DialogSuccess from '../Success';
import Button from '../../Controls/Button';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function DialogAdd({ btnName, title, children, openDialog, variant, icon }) {


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        openDialog()

    };


    return (
        <div>
            <Button  text={btnName}  variant={variant} onClick={handleClickOpen}>
                <Typography className='icon-btn' fontWeight={'bolder'} marginX={1} >{icon}</Typography>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >

                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography marginTop={5} fontWeight={'bolder'} variant='h5' component={'h2'}>
                        <div className='dialog-content'>
                            {title}
                        </div>
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent>
                    <div className='dialog-content'>
                        {children}
                    </div>
                </DialogContent>


                <DialogActions>
                    <Grid container justifyContent={'center'}>
                        <Grid item>
                            <Button className='btn-popup' variant='contained' autoFocus onClick={handleClose}>
                                {btnName}
                            </Button>
                        </Grid>
                    </Grid>

                </DialogActions>
            </BootstrapDialog>

        </div>
    );
}