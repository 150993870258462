import { Button, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import gallery1 from '../../assets/images/home/gallery1.png';
import { Link, useNavigate } from "react-router-dom";
const Action = ({ image, title, description, page }) => {

  const navigate = useNavigate();
  return (
    <div>
      <Link to={page}>

        <Card className='action'>
          <CardActionArea>
            <img
              className='action__media'
              src={image}
              alt="icon"
            />
            <CardContent>
              <Typography className='action__title font-bolder' gutterBottom variant="h5" component="div">
                {title}
              </Typography>
              <Typography className='action__description' variant="body2" color="text.secondary">
                {description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </div>
  )
}

export default Action