import { Autocomplete, Button, Card, CardActionArea, CardContent, FormControl, FormLabel, Grid, Paper, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useState } from 'react'
import group from '../../assets/images/profile/group.svg';
import avatar from '../../assets/images/public/avatar.svg'
import email from '../../assets/images/public/email.svg'
import phone from '../../assets/images/public/phone.svg'
import header from '../../assets/images/opinions/header.png';
import Intro from '../../components/Intro';
import dummyData from '../../plugins/data/data';
import DialogAdd from '../../components/Dialog/Add';
import AddSons from '../../components/FormsWebsite/Profile/AddSons';
import AddWife from '../../components/FormsWebsite/Profile/AddWife';
import { useNavigate } from "react-router-dom";
import AddFather from '../../components/FormsWebsite/Profile/AddFather';
const Profile = () => {
    const navigate = useNavigate();
    return (
        <section className='profile'>
            <Intro image={header} />
            <div className='mt-2'>
                <Container>
                    <Grid container xs spacing={3}>
                        <Grid item lg={8} xs={12} >
                            <Paper className='profile__right'>

                                <div className='text-center'>
                                    <Typography marginTop={2} className='font-bolder' gutterBottom variant="h5" component="div">
                                        بيانات العائلة
                                    </Typography>
                                    <div>
                                        <img
                                            src={group}
                                            alt="avatar"
                                        />
                                    </div>
                                </div>
                                <Typography className='font-bolder' gutterBottom variant="h6" component="div">
                                    أفراد العيلة
                                </Typography>

                                <div className='mt-2'>

                                    <FormLabel> اسم الأب </FormLabel>
                                    <Grid container alignItems={'center'} >

                                        <Grid item flexGrow={1}>
                                            <FormControl fullWidth variant="outlined">

                                                <Autocomplete
                                                    fullWidth
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={dummyData}

                                                    renderInput={(params) => <TextField {...params} label=" اسم الأب " />}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <DialogAdd icon={''} variant={'text'} openDialog={''} btnName="إضافة " title=" إضافة أب">
                                                <AddFather></AddFather>
                                            </DialogAdd>
                                        </Grid>

                                    </Grid>
                                </div>


                                <div className='mt-2'>

                                    <FormLabel>اسم الأم </FormLabel>
                                    <Grid container alignItems={'center'} >

                                        <Grid item flexGrow={1}>
                                            <FormControl fullWidth variant="outlined">

                                                <Autocomplete
                                                    fullWidth
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={dummyData}


                                                    renderInput={(params) => <TextField {...params} label="اسم الأم " />}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item>
                                            <DialogAdd icon={''} variant={'text'} openDialog={''} btnName="إضافة " title=" إضافةأم">
                                                <AddSons></AddSons>
                                            </DialogAdd>
                                        </Grid>


                                    </Grid>
                                </div>



                                <div className='mt-2'>

                                    <FormLabel>اسم العائلة </FormLabel>
                                    <Grid container alignItems={'center'} >

                                        <Grid item flexGrow={1}>
                                            <FormControl fullWidth variant="outlined">

                                                <Autocomplete
                                                    fullWidth
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={dummyData}


                                                    renderInput={(params) => <TextField {...params} label="اسم العائلة " />}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <DialogAdd icon={''} variant={'text'} openDialog={''} btnName="إضافة " title=" إضافة عائلة">
                                                <AddSons></AddSons>
                                            </DialogAdd>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div>
                                    <Grid container spacing={3}>
                                        <Grid item>

                                            <DialogAdd icon={''} variant={'text'} openDialog={''} btnName="إضافة زوجة" title="إضافة زوجة">
                                                <AddWife></AddWife>
                                            </DialogAdd>
                                        </Grid>
                                        <Grid item>
                                            <DialogAdd icon={''} variant={'text'} openDialog={''} btnName="إضافة ابن / ابنة" title="إضافة ابن / ابنة">
                                                <AddSons></AddSons>
                                            </DialogAdd>
                                        </Grid>

                                    </Grid>
                                </div>
                            </Paper>

                        </Grid>

                        <Grid item lg={4}  xs={12}>
                            <Paper className='profile__left'>

                                <div className='text-center'>
                                    <Typography marginTop={2} className='font-bolder' gutterBottom variant="h6" component="div">
                                        بياناتي الشخصية
                                    </Typography>
                                    <Grid xs container direction="row-reverse" spacing={2}>
                                        <Grid item>
                                            <Typography className='font-bolder view-link' gutterBottom variant="body1" component="p" >
                                                الملف الشخصي كعرض
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <div>
                                        <div className='avatar-container'>
                                            <div className='avatar-container__online'>
                                            </div>
                                            <img
                                                src={avatar}
                                                alt="avatar"
                                            />
                                        </div>
                                        <Typography fontWeight={'bolder'} gutterBottom variant="body1" component="p" >
                                            أمجد الشامي
                                        </Typography>
                                    </div>
                                </div>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item>
                                        <img
                                            src={phone}
                                            alt="phone"
                                        />
                                    </Grid>
                                    <Grid item>
                                        +966 3432 4222
                                    </Grid>
                                </Grid>
                                <div className='mt-1'>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item>
                                            <img
                                                src={email}
                                                alt="email"
                                            />
                                        </Grid>
                                        <Grid item>
                                            user@gmail.com
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className='mt-2 text-center'>
                                    <Button variant='contained' onClick={() => navigate('/profile/edit')} >
                                        تعديل البيانات
                                    </Button>
                                </div>

                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </div>

        </section>

    )
}



export default Profile