import React from 'react'
import Intro from '../../components/Intro'
import header from '../../assets/images/events/header.png';
import address from '../../assets/images/events/address.png';
import calender from '../../assets/images/events/calender.png';
import clock from '../../assets/images/events/clock.png';
import notifyActive from '../../assets/images/events/notify-active.png';
import notify from '../../assets/images/events/notify.png';
import eventImage from '../../assets/images/events/event.png';


import { Box, Container } from '@mui/system';
import { Button, ButtonBase, Card, CardContent, CardMedia, Grid, IconButton, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AnimatedPage from '../../components/Animations/AnimatedPages';
import DialogAdd from '../../components/Dialog/Add';
import AddEvent from '../../components/FormsWebsite/Events/AddEvent';


const Events = () => {
    return (
        <AnimatedPage>
            <section className='events page'>
                <Intro image={header} />
                <div className='events__content'>
                    <Container>
                        <Grid item xs container direction="row-reverse" spacing={2}>
                            {/* <Button variant="contained" className='add-data'>
                                <span className='icon'>  +   </span>  إضافة فعالية
                            </Button> */}

                            <DialogAdd icon={'+'} variant={'contained'} openDialog={''} btnName="إضافة فعالية" title="إضافة فعالية">
                                <AddEvent></AddEvent>
                            </DialogAdd>
                        </Grid>
                        <div className='events__content__card'>
                            <Link to="/events/details">
                                <Paper className='paper'
                                    sx={{
                                        p: 1,
                                        margin: 'auto',
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase sx={{ width: 128, height: 128 }}>
                                                <img src={eventImage} alt="event" className='events__content__card__media' />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                        حفل إفطار رمضان الجماعي
                                                    </Typography>
                                                    <Typography className='text-name' variant="body2" gutterBottom>
                                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                    </Typography>

                                                </Grid>
                                                <Grid item>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={calender} alt="calender" className='event-icon' />

                                                                <span>
                                                                    الجمعة، 5 رمضان 1443
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={clock} alt="clock" className='event-icon' />
                                                                <span>
                                                                    6:00 مساءًا
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={address} alt="address" className='event-icon' />
                                                                <span>
                                                                    مكة المكرمة، حي شرق
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                            <Grid item className='notify'>
                                                <Typography variant="subtitle1" component="div">
                                                    <img src={notify} alt="address" className='event-icon' />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Link>
                        </div>
                        <div className='events__content__card'>
                            <Link to="/events/details">
                                <Paper className='paper'
                                    sx={{
                                        p: 1,
                                        margin: 'auto',
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase sx={{ width: 128, height: 128 }}>
                                                <img src={eventImage} alt="event" className='events__content__card__media' />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                        حفل إفطار رمضان الجماعي
                                                    </Typography>
                                                    <Typography className='text-name' variant="body2" gutterBottom>
                                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                    </Typography>

                                                </Grid>
                                                <Grid item>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={calender} alt="calender" className='event-icon' />

                                                                <span>
                                                                    الجمعة، 5 رمضان 1443
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={clock} alt="clock" className='event-icon' />
                                                                <span>
                                                                    6:00 مساءًا
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={address} alt="address" className='event-icon' />
                                                                <span>
                                                                    مكة المكرمة، حي شرق
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                            <Grid item className='notify'>
                                                <Typography variant="subtitle1" component="div">
                                                    <img src={notify} alt="address" className='event-icon' />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Link>
                        </div>
                        <div className='events__content__card'>
                            <Link to="/events/details">
                                <Paper className='paper'
                                    sx={{
                                        p: 1,
                                        margin: 'auto',
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase sx={{ width: 128, height: 128 }}>
                                                <img src={eventImage} alt="event" className='events__content__card__media' />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                        حفل إفطار رمضان الجماعي
                                                    </Typography>
                                                    <Typography className='text-name' variant="body2" gutterBottom>
                                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                    </Typography>

                                                </Grid>
                                                <Grid item>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={calender} alt="calender" className='event-icon' />

                                                                <span>
                                                                    الجمعة، 5 رمضان 1443
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={clock} alt="clock" className='event-icon' />
                                                                <span>
                                                                    6:00 مساءًا
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={address} alt="address" className='event-icon' />
                                                                <span>
                                                                    مكة المكرمة، حي شرق
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                            <Grid item className='notify'>
                                                <Typography variant="subtitle1" component="div">
                                                    <img src={notify} alt="address" className='event-icon' />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Link>
                        </div>
                        <div className='events__content__card'>
                            <Link to="/events/details">
                                <Paper className='paper'
                                    sx={{
                                        p: 1,
                                        margin: 'auto',
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase sx={{ width: 128, height: 128 }}>
                                                <img src={eventImage} alt="event" className='events__content__card__media' />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                        حفل إفطار رمضان الجماعي
                                                    </Typography>
                                                    <Typography className='text-name' variant="body2" gutterBottom>
                                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                    </Typography>

                                                </Grid>
                                                <Grid item>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={calender} alt="calender" className='event-icon' />

                                                                <span>
                                                                    الجمعة، 5 رمضان 1443
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={clock} alt="clock" className='event-icon' />
                                                                <span>
                                                                    6:00 مساءًا
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={address} alt="address" className='event-icon' />
                                                                <span>
                                                                    مكة المكرمة، حي شرق
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                            <Grid item className='notify'>
                                                <Typography variant="subtitle1" component="div">
                                                    <img src={notify} alt="address" className='event-icon' />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Link>
                        </div>

                        <div className='events__content__card'>
                            <Link to="/events/details">
                                <Paper className='paper'
                                    sx={{
                                        p: 1,
                                        margin: 'auto',
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase sx={{ width: 128, height: 128 }}>
                                                <img src={eventImage} alt="event" className='events__content__card__media' />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                        حفل إفطار رمضان الجماعي
                                                    </Typography>
                                                    <Typography className='text-name' variant="body2" gutterBottom>
                                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                    </Typography>

                                                </Grid>
                                                <Grid item>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={calender} alt="calender" className='event-icon' />

                                                                <span>
                                                                    الجمعة، 5 رمضان 1443
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={clock} alt="clock" className='event-icon' />
                                                                <span>
                                                                    6:00 مساءًا
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={address} alt="address" className='event-icon' />
                                                                <span>
                                                                    مكة المكرمة، حي شرق
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                            <Grid item className='notify'>
                                                <Typography variant="subtitle1" component="div">
                                                    <img src={notify} alt="address" className='event-icon' />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Link>
                        </div>
                        <div className='events__content__card'>
                            <Link to="/events/details">
                                <Paper className='paper'
                                    sx={{
                                        p: 1,
                                        margin: 'auto',
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase sx={{ width: 128, height: 128 }}>
                                                <img src={eventImage} alt="event" className='events__content__card__media' />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                        حفل إفطار رمضان الجماعي
                                                    </Typography>
                                                    <Typography className='text-name' variant="body2" gutterBottom>
                                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                    </Typography>

                                                </Grid>
                                                <Grid item>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={calender} alt="calender" className='event-icon' />

                                                                <span>
                                                                    الجمعة، 5 رمضان 1443
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={clock} alt="clock" className='event-icon' />
                                                                <span>
                                                                    6:00 مساءًا
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={address} alt="address" className='event-icon' />
                                                                <span>
                                                                    مكة المكرمة، حي شرق
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                            <Grid item className='notify'>
                                                <Typography variant="subtitle1" component="div">
                                                    <img src={notify} alt="address" className='event-icon' />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Link>
                        </div>
                        <div className='events__content__card'>
                            <Link to="/events/details">
                                <Paper className='paper'
                                    sx={{
                                        p: 1,
                                        margin: 'auto',
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase sx={{ width: 128, height: 128 }}>
                                                <img src={eventImage} alt="event" className='events__content__card__media' />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                        حفل إفطار رمضان الجماعي
                                                    </Typography>
                                                    <Typography className='text-name' variant="body2" gutterBottom>
                                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص
                                                    </Typography>

                                                </Grid>
                                                <Grid item>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={calender} alt="calender" className='event-icon' />

                                                                <span>
                                                                    الجمعة، 5 رمضان 1443
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={clock} alt="clock" className='event-icon' />
                                                                <span>
                                                                    6:00 مساءًا
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={address} alt="address" className='event-icon' />
                                                                <span>
                                                                    مكة المكرمة، حي شرق
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                            <Grid item className='notify'>
                                                <Typography variant="subtitle1" component="div">
                                                    <img src={notify} alt="address" className='event-icon' />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Link>
                        </div>
                    </Container>
                </div>
            </section>
        </AnimatedPage>
    )
}

export default Events