import Grid from '@mui/material/Grid'
import React from 'react'
import Action from '../../Action'
import DialogAdd from '../../Dialog/Add'
import money from '../../../assets/images/requests/money.svg';
import AddAdvance from '../../FormsWebsite/Requests/AddAdvance';


import { useState } from 'react';
import DialogSuccess from '../../Dialog/Success';
const RequestAdvance = () => {

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: 'تم إرسال طلب السلفة للإدارة بنجاح',
            subTitle: 'سوف يتم مراجعة بيانات ملاحظاتك مع الإدارة وإرسال الرد لك',
            onConfirm: () => { alert("hi") }
        })
    }
    return (
        <Grid item xs={12} sm={6}>
            <Action
                page={'/requests/previous'}
                image={money}
                title='سلفة'
                description='هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، '
                btns={true} >
                <DialogAdd icon={''} variant={'contained'} openDialog={openDialog} btnName="تقديم الطلب " title="تقديم طلب سلفة">
                    <AddAdvance></AddAdvance>
                </DialogAdd>
            </Action>
            <DialogSuccess confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}></DialogSuccess>

        </Grid>
    )
}

export default RequestAdvance