import React from 'react'

const Question = ({ image, title }) => {
    return (
        <div className='question'>
            <div className='question__media'>
                <img src={image} alt="question" />
            </div>
            <p className='question__title text-center'>
                {title}
            </p>
        </div>
    )
}

export default Question