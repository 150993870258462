import { Box, Button, FormControl, FormLabel, Grid, TextField } from "@mui/material"
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import logo from '../../../../assets/images/home/logo.svg';
import AnimatedPage from "../../../../components/Animations/AnimatedPages";
import Form from "../../../../components/Login/Form";
import Preloader from "../../../../components/Preloader";
import { useNavigate } from "react-router-dom";

const ResetPasswordEmail = () => {
    const navigate = useNavigate();
    return (
        <AnimatedPage>
            <Preloader />
            <section className='auth'>
                <Grid container spacing={1}>
                    <Grid className="auth__right" item xs={12} sm={6}>
                        <div >
                            <Container>
                                <div className="text-center mt-3">
                                    <Link to="/" >
                                        <img className='logo' src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <h2 className="mt-1">
                                    استعادة كلمة المرور
                                </h2>
                                <FormLabel>البريد الالكتروني</FormLabel>
                                <FormControl fullWidth variant="outlined" >
                                    <TextField
                                        fullWidth
                                        id="outlined-text-input"
                                        label={' قم بكتابة البريد الالكتروني هنا...'}
                                        type="text"
                                    />
                                </FormControl>
                                <Box p={1} mt={5}>
                                    <Grid container lg={12} justifyContent="center">
                                        <Grid item>
                                            <Button type="submit" variant="contained" onClick={() => navigate('/auth/verify-code/email')}>
                                                إرسال
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Container>
                        </div>
                    </Grid>
                    <Grid className="auth__left" item xs={12} sm={6}>
                    </Grid>
                </Grid>
            </section>
        </AnimatedPage>
    )
}

export default ResetPasswordEmail