import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import dummyData from '../../../../plugins/data/data';
import UploadFile from '../../../Forms/UploadFile';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const AddOffer = () => {
    const [value, setValue] = useState<Dayjs | null>(null);
    return (

        <form>
            <div>
                <FormLabel>
                    صورةالعرض
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <UploadFile title={'يرجى إرفاق صورةالعرض التي تريدها'} />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    اسم العرض
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        label={' اسم العرض..'}
                    />
                </FormControl>
            </div>

            <div>
                <FormLabel>
                    نوع العرض
                </FormLabel>
                <FormControl fullWidth variant="outlined" >

                    <Autocomplete
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        options={dummyData}
                        renderInput={(params) => <TextField {...params} label="نوع العرض " />}
                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    مكان العرض
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        label={' مكان العرض..'}
                    />
                </FormControl>
            </div>
            <div>
                <FormLabel>
                    تاريخ  بداية العرض
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="تاريخ  بداية العرض"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                </FormControl>
            </div>
            <div>
                <FormLabel>
                    تاريخ  نهاية العرض
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="تاريخ  نهاية العرض"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                </FormControl>
            </div>
            <div>
                <FormLabel>
                    وصف العرض
                </FormLabel>
                <FormControl fullWidth variant="outlined" >
                    <TextField
                        fullWidth
                        id="outlined-multiline-static"
                        label={' وصف العرض..'}
                        multiline
                        rows={1}
                        defaultValue=""
                    />
                </FormControl>
            </div>
        </form>

    )
}

export default AddOffer