
import { Grid, TextField } from "@mui/material";
import { ChangeEvent, useRef, useState } from 'react';
import { useForm } from "react-hook-form";

import fileImage from "../../../assets/images/icons/file.svg"
const UploadFile = ({title}) => {

    const inputElement = useRef();


    const [file, setFile] = useState<File>();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        let file = e.target.files[0]
        let src = URL.createObjectURL(file);
        console.log(src)
        if (file) {
            setFile(file);
        }

    };

    const handleUploadClick = () => {
        if (!file) {
            return;
        }
    }


    return (
        <div className="file-upload">
            <input accept="image/*" type="file" onChange={handleFileChange} />
            <Grid container alignItems={'center'} spacing={'2'}>
                <Grid item >
                    <img id="image" ref={inputElement} src={fileImage} alt="file" />
                </Grid>
                <Grid item>
                    {!file && <p>
                     {title}
                    </p>
                    }
                    <div>{file && `${file.name}`}</div>
                </Grid>
            </Grid>
        </div>

    )
}

export default UploadFile