import { Fragment } from "react";
import { Outlet } from "react-router-dom";

export default function Auth() {

    return (
        <Fragment>

            <Outlet></Outlet>

        </Fragment>
    );
}
