import { Box, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, TextField } from "@mui/material"
import { Container } from "@mui/system";
import { FormProvider } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../../assets/images/home/logo.svg';
import AnimatedPage from "../../../components/Animations/AnimatedPages";
import React, { useRef, useState, useEffect } from "react";

import Preloader from "../../../components/Preloader";
import DialogSimple from "../../../components/Dialog/Simple";
import Button from "../../../components/Controls/Button";
import Close from "../../../assets/images/icons/close.svg";
import Eye from "../../../assets/images/icons/eye.png";



import { useDispatch } from 'react-redux'
import { useLoginMutation } from "../../../plugins/features/auth/login";
import { setCredentials } from "../../../plugins/features/auth/userAuth";


const Login = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [resetPage, setResetPage] = useState('phone');
    const navigate = useNavigate();


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleClickResetPage = (event) => {
        setResetPage(event.target.value);
    }

    const goRoute = () => {
        navigate(`/auth/reset-password/${resetPage}`)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const userRef = useRef()
    const errRef = useRef()
    const [phone, setPhone] = useState('admin@admin.com')
    const [password, setPassword] = useState('password')
    const [errMsg, setErrMsg] = useState('')
    const [device, setDevice] = useState('chrome')


    const [login, { isLoading }] = useLoginMutation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (userRef.current) {
            userRef.current.focus()
        }

    }, [])

    useEffect(() => {
        setErrMsg('')
    }, [phone, password])

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const userData = await login().unwrap()
            const user = userData.user
            const token= user.token

            dispatch(setCredentials({ user,token}))
            setPhone('')
            setPassword('')
            navigate('/welcome')
        } catch (err) {
            if (!err?.originalStatus) {
                // isLoading: true until timeout occurs
                setErrMsg('No Server Response');
            } else if (err.originalStatus === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.originalStatus === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    const handlephoneInput = (e) => setPhone(e.target.value)

    const handlePasswordInput = (e) => setPassword(e.target.value)


    return (
        <AnimatedPage>
            <Preloader />
            <section className='auth'>
                <Grid container spacing={1}>

                    <Grid className="auth__right" item xs={12} sm={6}>
                        <div >
                            <Container>
                                <div className="text-center">
                                    <Link to="/" >
                                        <img className='logo' src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <h2>
                                    تسجيل الدخول
                                </h2>
                                <div>
                                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                                    <form onSubmit={handleSubmit}>
                                        <div className="mt-1">
                                            <div>
                                                <FormLabel> البريد الالكتروني / رقم الجوال </FormLabel>
                                                <FormControl fullWidth variant="outlined" >
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-text-input"
                                                        label={' قم بكتابة البريد الالكتروني / رقم الجوال هنا...'}
                                                        type="text"
                                                        ref={userRef}
                                                        value={phone}
                                                        onChange={handlephoneInput}
                                                        required
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <FormLabel> كلمة المرور</FormLabel>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password"> قم بكتابة كلمة المرور هنا... </InputLabel>
                                                <OutlinedInput
                                                    onChange={handlePasswordInput}
                                                    value={password}
                                                    required
                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <img width={25} src={Eye} alt="eye" /> : <img src={Close} alt="close" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </div>

                                        <div>
                                            <DialogSimple goRoute={goRoute} title={'اختيار طريقة استعادة كلمة المرور'}>
                                                <RadioGroup
                                                    onChange={handleClickResetPage}
                                                    value={resetPage}
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                >
                                                    <FormControlLabel value="phone" control={<Radio />} label="رقم الجوال" />
                                                    <FormControlLabel value="phone" control={<Radio />} label="البريد الألكترونى" />
                                                </RadioGroup>
                                            </DialogSimple>
                                        </div>
                                        <Box p={1} mt={2}>
                                            <Grid container lg={12} justifyContent="center">
                                                <Grid item >
                                                    <Button type="submit" variant="contained" >
                                                        تسجيل الدخول
                                                    </Button>
                                                    <div>
                                                        <Link className="auth__link font-bolder" to="/auth/register" >
                                                            <span>
                                                                ليس لديك حساب؟
                                                            </span>
                                                            <span>
                                                                إنشاء حساب
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </form>

                                </div>
                            </Container>
                        </div>
                    </Grid>
                    <Grid className="auth__left" item xs={12} sm={6}>
                    </Grid>
                </Grid>
            </section>
        </AnimatedPage>
    )
}

export default Login