import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import logo from '../../../assets/images/home/logo.svg';
import avatar from '../../../assets/images/icons/avatar.svg';
import camera from '../../../assets/images/icons/camera.svg';
import AnimatedPage from "../../../components/Animations/AnimatedPages";
import CustomizedSwitches from "../../../components/Forms/Switch";
import Form from "../../../components/Login/Form";
import Preloader from "../../../components/Preloader";
import Close from "../../../assets/images/icons/close.svg";
import Eye from "../../../assets/images/icons/eye.png";
import { useState } from "react"

const Register = () => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((showConfirm) => !showConfirm);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <AnimatedPage>
            <Preloader />
            <section className='auth register'>
                <Grid container spacing={1}>

                    <Grid className="auth__right" item xs={12} sm={6}>
                        <div >
                            <Container>
                                <div>
                                    <Link to="/" >
                                        <img className='logo' src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <h2>
                                    إنشاء حساب
                                </h2>
                                <div className="user-image text-center">
                                    <img className='user-image__avatar' src={avatar} alt="logo" />
                                    <img className='user-image__camera' src={camera} />
                                </div>
                                <div className="text-center">
                                    <CustomizedSwitches></CustomizedSwitches>
                                </div>
                                <div>
                                    <FormLabel>
                                        اسم المستخدم
                                        <span className="text-danger">
                                            *
                                        </span>
                                    </FormLabel>
                                    <FormControl fullWidth variant="outlined" >
                                        <TextField
                                            fullWidth
                                            id="outlined-text-input"
                                            label={' قم بكتابة اسم المستخدم هنا...'}
                                            type="text"
                                        />
                                    </FormControl>
                                </div>
                                <div>
                                    <FormLabel>
                                        البريد الالكتروني
                                        <span className="text-danger">
                                            *
                                        </span>
                                    </FormLabel>
                                    <FormControl fullWidth variant="outlined" >
                                        <TextField
                                            fullWidth
                                            id="outlined-text-input"
                                            label={' قم بكتابة البريد الالكتروني هنا...'}
                                            type="text"
                                        />
                                    </FormControl>
                                </div>

                                <div>
                                    <FormLabel>
                                        رقم الجوال
                                        <span className="text-danger">
                                            *
                                        </span>
                                    </FormLabel>
                                    <FormControl fullWidth variant="outlined" >
                                        <TextField
                                            fullWidth
                                            id="outlined-text-input"
                                            label={' قم بكتابة رقم الجوال  هنا...'}
                                            type="text"
                                        />
                                    </FormControl>
                                </div>


                                <div>
                                    <FormLabel>
                                        رقم الهوية
                                        <span className="text-danger">
                                            *
                                        </span>
                                    </FormLabel>
                                    <FormControl fullWidth variant="outlined" >
                                        <TextField
                                            fullWidth
                                            id="outlined-text-input"
                                            label={' قم بكتابة رقم الهوية   هنا...'}
                                            type="text"
                                        />
                                    </FormControl>
                                </div>

                                <div className="check-options">
                                    <FormLabel>
                                        الجنس
                                        <span className="text-danger">
                                            *
                                        </span>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                    >

                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FormControlLabel value="1" control={<Radio />} label="ذكر" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel value="0" control={<Radio />} label="أنثى" />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>

                                </div>


                                <div>
                                    <FormLabel>
                                        كلمة المرور
                                        <span className="text-danger">
                                            *
                                        </span>
                                    </FormLabel>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password"> قم بكتابة كلمة المرور هنا... </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <img width={25} src={Eye} alt="eye" /> : <img src={Close} alt="close" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                </div>

                                <div className="mt-1">
                                    <FormLabel>
                                        تأكيد كلمة المرور
                                        <span className="text-danger">
                                            *
                                        </span>
                                    </FormLabel>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password"> قم بكتابة كلمة المرور هنا... </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPasswordConfirm ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPasswordConfirm}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPasswordConfirm ? <img width={25} src={Eye} alt="eye" /> : <img src={Close} alt="close" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                </div>

                                <div className="check-options mt-1">
                                    <FormLabel>
                                        هل الأب من عائلة أزهر؟
                                        <span className="text-danger">
                                            *
                                        </span>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                    >

                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FormControlLabel value="1" control={<Radio />} label="نعم" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel value="0" control={<Radio />} label="لا" />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>

                                </div>

                                <div className="check-options">
                                    <FormLabel>
                                        هل الأم من عائلة أزهر؟
                                        <span className="text-danger">
                                            *
                                        </span>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                    >
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FormControlLabel value="1" control={<Radio />} label="نعم" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel value="0" control={<Radio />} label="لا" />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>

                                </div>

                                <div>

                                    <Box p={1} mt={2}>
                                        <Grid container lg={12} justifyContent="center">
                                            <Grid item  >
                                                <Button type="submit" variant="contained" >
                                                    إنشاء
                                                </Button>
                                                <div className="text-center">
                                                    <Link className="auth__link font-bolder" to="/auth/login" >
                                                        <span>
                                                            لديك حساب بالفعل ؟
                                                        </span>
                                                        <span>
                                                            قم بتسجيل الدخول
                                                        </span>
                                                    </Link>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </Container>
                        </div>
                    </Grid>
                    <Grid className="auth__left" item xs={12} sm={6}>
                    </Grid>
                </Grid>
            </section>
        </AnimatedPage>
    )
}

export default Register