import { Grid, Typography } from '@mui/material'
import React from 'react'
import person from '../../../assets/images/blogs/person.png';

const Comment = ({ name, description, time }) => {
    return (

        <div className='comments'>

            <Grid container justifyContent='space-between' marginBottom={2}>
                <Grid item xs={9}>
                    <Grid container align-alignItems='center' alignContent={'center'}>
                        <Grid>
                            <img src={person} alt="commentor" />
                        </Grid>
                        <Grid className='px-1'>
                            <Typography variant='h6' component={'h3'} className='font-bolder'>
                                {name}
                            </Typography>
                            <p className='comments__comment'>
                                {description}
                            </p>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={3} textAlign={'left'}>
                    <span className='comments__time'>
                        تم التعليق منذ {time}
                    </span>
                </Grid>
            </Grid>


        </div>
    )
}

export default Comment