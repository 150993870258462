import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import removeImage from '../../../assets/images/icons/remove.svg';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function DialogDelete({ title }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button className='font-bolder' color='error' onClick={handleClickOpen}>
                حذف
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography className='text-danger heading-dialog' textAlign={'center'} marginTop={5} fontWeight={'bolder'} variant='h5' component={'h2'}>
                        <Box marginBottom={1}>
                            <img src={removeImage} alt="delete" />
                        </Box>
                        { title }
                    </Typography>
                </BootstrapDialogTitle>

                <DialogActions>
                    <Grid marginBottom={2} container justifyContent={'center'}>
                        <Grid item marginX={2}>
                            <Button variant='contained' autoFocus onClick={handleClose}>
                                تراجع
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='outlined' color='error' autoFocus onClick={handleClose}>
                                حذف
                            </Button>
                        </Grid>
                    </Grid>

                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}