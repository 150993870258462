import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Blog = ({ title, image, description, id }) => {
    return (
        <div className="gallery__wrap">
            <Link to="/blogs/details">
                <div>
                    <img src={image} alt="tree image1" />
                </div>
                <Grid item xs container direction="row-reverse" spacing={2}>
                    <Typography className='hint'>
                        تم النشر في 13/4/2022 في الساعة 3:14
                    </Typography>
                </Grid>
                <h3>
                    {title}
                </h3>
                {
                    description &&

                    <p>
                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث لأن يمكنك أن تولد مثل هذا النص أو
                    </p>
                }
            </Link>
        </div>

    )
}

export default Blog