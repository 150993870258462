import { Typography } from '@mui/material'
import React from 'react'

const ProgressBar = ({ text, percent }) => {
    return (
        <div className='progress'>
            <Typography variant='body1' component='p' className='font-bolder'>
                {text}
            </Typography>

            <div className='progress__bar'>
                <div className='progress__bar__percent' style={{width:percent+'%'}}>
                </div>
            </div>


        </div>
    )
}

export default ProgressBar