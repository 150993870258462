import { Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../../components/Intro'
import Item from '../../../components/Blog'
import header from '../../../assets/images/gallery/header.png';
import gallery1 from '../../../assets/images/home/gallery1.png';
import gallery2 from '../../../assets/images/home/gallery2.png';
import gallery3 from '../../../assets/images/home/gallery3.png';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import DialogAdd from '../../../components/Dialog/Add';
import AddAdGallery from '../../../components/FormsWebsite/Gallery/AddGallery';

import { useState } from 'react';
const GalleryDetails = () => {

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: 'تمت إضافة الصور بنجاح',
            subTitle: 'سوف يتم مراجعة البيانات  مع الإدارة وإرسال الرد لك',
            onConfirm: () => { alert("hi") }
        })
    }

    return (
        <AnimatedPage>
            <section className='gallery page'>
                <Intro image={header} />
                <div className='page__content '>
                    <Container>
                        <Grid xs container direction="row-reverse" spacing={2}
                            alignItems="center">
                            <Grid item xs={12} sm={4} className='text-left px-0' >
                                <DialogAdd icon={'+'} variant={'contained'} openDialog={openDialog} btnName="إضافة محتوى" title="إضافة ألبوم">
                                    <AddAdGallery />
                                </DialogAdd>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='h5' component='h2' className='text-center font-bolder'>
                                    ألبوم العمل
                                </Typography>
                            </Grid>
                        </Grid>
                        <div className='gallery__cards'>

                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={4}>
                                    <Item description='true' image={gallery1} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item description='true' image={gallery2} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item description='true' image={gallery3} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item description='true' image={gallery1} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item description='true' image={gallery2} title="مشاركة التعليم" id={1} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Item description='true' image={gallery3} title="مشاركة التعليم" id={1} />
                                </Grid>
                            </Grid>
                        </div>

                    </Container>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default GalleryDetails