import { initReactI18next } from 'react-i18next'
// import HttpApi from 'i18next-http-backend'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ar from "./locales/ar/translation.json";
import en from "./locales/en/translation.json";

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: {
                    ...en
                },
            },
            ar: {
                translation: {
                    ...ar
                },
            },
        },
        supportedLngs: ['en', 'ar'],
        fallbackLng: 'en',
        debug: false,
        detection: {
            order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
            caches: ['cookie'],
        }
    });