import { Grid, Typography } from '@mui/material'
import google from '../../assets/images/home/google.png';
import apple from '../../assets/images/home/apple2.png';
import logo from '../../assets/images/home/logo.svg';
import huwai from '../../assets/images/home/huwai.png';

const Soon = () => {
    return (
        <section className='soon'>
            <div>
                <img className='logo' src={logo} alt="logo" />
            </div>
            <Typography fontSize={30} marginBottom={10} fontWeight={'bolder'} textAlign={'center'}>
                سيتم الأفتتاح قريبا
            </Typography>
            <Grid container alignContent={'center'} alignItems={'center'} spacing={2} justifyContent={'center'}>


                <Grid item textAlign={'center'}>
                    <a target={'_blank'} href="https://apps.apple.com/eg/app/%D8%B9%D8%A7%D8%A6%D9%84%D8%A9-%D8%A7%D9%84%D8%A7%D8%B2%D9%87%D8%B1/id6443561128">
                        <img src={apple} alt="apple" />
                    </a>
                </Grid>
                <Grid item textAlign={'center'}>
                    <a target={'_blank'} href="https://play.google.com/store/apps/details?id=com.hulul.azharfamily">
                        <img src={google} alt="android" />
                    </a>
                </Grid>
                <Grid item textAlign={'center'}>
                    <a target={'_blank'} href="https://appgallery.huawei.com/#/app/C107488203">
                        <img src={huwai} alt="apple" />
                    </a>
                </Grid>



            </Grid>
        </section>
    )
}

export default Soon