import { configureStore } from "@reduxjs/toolkit"
import { baseApi } from "./api/base"
import authReducer from './features/auth/userAuth'

export const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
        auth: authReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(baseApi.middleware),
    devTools: true
})