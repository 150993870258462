import React from 'react'
import { Link } from 'react-router-dom'

const Item = ({ title, image, id }) => {
    return (
        <div className="gallery__wrap">
            <Link to="/gallery/details">
                <div>
                    <img src={image} alt="tree image1" />
                </div>
                <h3 className='text-center'>
                    {title}
                </h3>
            </Link>
        </div>

    )
}

export default Item