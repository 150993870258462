const Preloader = () => {
    return (
        <div className="loader-wrap style-four">
            <div className="layer layer-one"><span className="overlay"></span></div>
            <div className="layer layer-two"><span className="overlay"></span></div>
            <div className="layer layer-three"><span className="overlay"></span></div>
        </div>
    )
}

export default Preloader