import { Button, ButtonBase, Grid, Paper, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../../components/Intro'
import Item from '../../../components/Blog'
import header from '../../../assets/images/donations/header.png';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import { useState } from 'react';
import DialogFire from '../../../components/Dialog/Fire';
import AddDonation from '../../../components/FormsWebsite/Requests/AddAdvance';


const New = () => {

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: 'تقديم طلب تبرع',
            subTitle: '',
            onConfirm: () => { alert("hi") }
        })
    }
    return (
        <AnimatedPage>
            <DialogFire btnName="تأكيد التبرع" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                <AddDonation></AddDonation>
            </DialogFire>
            <section className='donations page bg-white'>
                <Intro image={header} />
                <div className='page__content mt-3'>
                    <div className='donations__prev'>
                        <Container>
                            <Typography className='font-bolder' gutterBottom variant="h5" component="h2">
                                تبرعات جديدة
                            </Typography>
                            <Paper className='donations__prev__card paper'
                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="row" spacing={2}>
                                            <Grid item md={10}>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom >
                                                    <span >
                                                        اسم الحساب:
                                                    </span>
                                                    <span className='text-primary'>
                                                        فرد من عائلة شجرة العيلة
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        رقم الحساب:
                                                    </span>
                                                    <span className='text-primary'>
                                                        10323434322
                                                    </span>
                                                </Typography>

                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        اسم البنك:
                                                    </span>

                                                    <span className='text-primary'>
                                                        البنك العربي السعودى
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        رقم الحساب الدولي:                                              </span>
                                                    <span className='text-primary'>
                                                        232223222
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        سويفت      :                                        </span>
                                                    <span className='text-primary'>
                                                        3324452
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item container flexDirection='column' alignItems='center' justifyContent='center' alignContent='center' md={2}>
                                                <Typography variant="subtitle1" className='text-left font-bolder donate-btn' component="div">
                                                    <Button variant='contained' onClick={() => openDialog()}>
                                                        تبرع الآن
                                                    </Button>
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper className='donations__prev__card paper'
                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="row" spacing={2}>
                                            <Grid item md={10}>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom >
                                                    <span >
                                                        اسم الحساب:
                                                    </span>
                                                    <span className='text-primary'>
                                                        فرد من عائلة شجرة العيلة
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        رقم الحساب:
                                                    </span>
                                                    <span className='text-primary'>
                                                        10323434322
                                                    </span>
                                                </Typography>

                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        اسم البنك:
                                                    </span>

                                                    <span className='text-primary'>
                                                        البنك العربي السعودى
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        رقم الحساب الدولي:                                              </span>
                                                    <span className='text-primary'>
                                                        232223222
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        سويفت      :                                        </span>
                                                    <span className='text-primary'>
                                                        3324452
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item container flexDirection='column' alignItems='center' justifyContent='center' alignContent='center' md={2}>
                                                <Typography variant="subtitle1" className='text-left font-bolder donate-btn' component="div">
                                                    <Button variant='contained' onClick={() => openDialog()} >
                                                        تبرع الآن
                                                    </Button>
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper className='donations__prev__card paper'
                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="row" spacing={2}>
                                            <Grid item md={10}>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom >
                                                    <span >
                                                        اسم الحساب:
                                                    </span>
                                                    <span className='text-primary'>
                                                        فرد من عائلة شجرة العيلة
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        رقم الحساب:
                                                    </span>
                                                    <span className='text-primary'>
                                                        10323434322
                                                    </span>
                                                </Typography>

                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        اسم البنك:
                                                    </span>

                                                    <span className='text-primary'>
                                                        البنك العربي السعودى
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        رقم الحساب الدولي:                                              </span>
                                                    <span className='text-primary'>
                                                        232223222
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        سويفت      :                                        </span>
                                                    <span className='text-primary'>
                                                        3324452
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item container flexDirection='column' alignItems='center' justifyContent='center' alignContent='center' md={2}>
                                                <Typography variant="subtitle1" className='text-left font-bolder donate-btn' component="div">
                                                    <Button variant='contained' onClick={() => openDialog()}>
                                                        تبرع الآن
                                                    </Button>
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper className='donations__prev__card paper'
                                sx={{
                                    p: 1,
                                    margin: 'auto',
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="row" spacing={2}>
                                            <Grid item md={10}>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom >
                                                    <span >
                                                        اسم الحساب:
                                                    </span>
                                                    <span className='text-primary'>
                                                        فرد من عائلة شجرة العيلة
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        رقم الحساب:
                                                    </span>
                                                    <span className='text-primary'>
                                                        10323434322
                                                    </span>
                                                </Typography>

                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        اسم البنك:
                                                    </span>

                                                    <span className='text-primary'>
                                                        البنك العربي السعودى
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        رقم الحساب الدولي:                                              </span>
                                                    <span className='text-primary'>
                                                        232223222
                                                    </span>
                                                </Typography>
                                                <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                    <span >
                                                        سويفت      :                                        </span>
                                                    <span className='text-primary'>
                                                        3324452
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item container flexDirection='column' alignItems='center' justifyContent='center' alignContent='center' md={2}>
                                                <Typography variant="subtitle1" className='text-left font-bolder donate-btn' component="div">
                                                    <Button variant='contained' onClick={() => openDialog()}>
                                                        تبرع الآن
                                                    </Button>
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>


                        </Container>
                    </div>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default New