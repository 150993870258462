import React from 'react'
import Intro from '../../../components/Intro'
import header from '../../../assets/images/events/header.png';
import address from '../../../assets/images/events/address.png';
import calender from '../../../assets/images/events/calender.png';
import clock from '../../../assets/images/events/clock.png';
import notifyActive from '../../../assets/images/events/notify-active.png';
import notify from '../../../assets/images/events/notify.png';
import eventImage from '../../../assets/images/events/event-details.png';


import { Box, Container } from '@mui/system';
import { Button, ButtonBase, Grid, Paper, Typography } from '@mui/material';
import AnimatedPage from '../../../components/Animations/AnimatedPages';


const EventDetails = () => {
    return (
        <AnimatedPage>
            <section className='events page'>
                <Intro image={header} />
                <div className='events__content'>
                    <Container>
                        <div className='events__content__card mt-3'>
                            <div className='details'>
                                <Paper className='paper paper--details'
                                    sx={{
                                        p: 1,
                                        margin: 'auto',
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <Grid container spacing={2} flexDirection='column'>

                                        <Grid item>

                                            <img src={eventImage} alt="event" className='events__content__card__media' />

                                        </Grid>
                                        <Grid item xs container direction="row-reverse" spacing={2}>
                                            <span className='hint'>
                                                العرض مقدم من أمجد الشامي
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                        حفل إفطار رمضان الجماعي
                                                    </Typography>

                                                </Grid>
                                                <Grid item>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={calender} alt="calender" className='event-icon' />

                                                                <span>
                                                                    الجمعة، 5 رمضان 1443
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={clock} alt="clock" className='event-icon' />
                                                                <span>
                                                                    6:00 مساءًا
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className='item-events'>
                                                            <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                <img src={address} alt="address" className='event-icon' />
                                                                <span>
                                                                    مكة المكرمة، حي شرق
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                                <Typography className='text-name mt-1 border-0' variant="body2" gutterBottom>
                                                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث لأن يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير من الأحيان أن يطلع على صورة حقيقية لتصميم الموقع.
                                                </Typography>
                                                <Grid item xs container spacing={2}>
                                                    <Grid item>
                                                        <Button className='primary' variant='contained'>
                                                            حضور
                                                        </Button>
                                                    </Grid>
                                                    <Grid item >
                                                        <Button className='secondary' variant='outlined'>
                                                            تذكير فيما بعد
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <Grid item className='notify'>
                                                <Typography variant="subtitle1" component="div">
                                                    <img src={notify} alt="address" className='event-icon notify-mobile' />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </Container>
                </div>
            </section>
        </AnimatedPage>
    )
}

export default EventDetails