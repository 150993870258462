
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';


// const loadingMarkup = (
//   <div className="py-4 text-center">
//     <h3>Loading..</h3>
//   </div>
// )

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(

    <React.StrictMode>
      <App />
    </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
